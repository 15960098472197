import instance from './request'

//获取设备履历
export function getResumeList(dataObj) {
  return instance({
    url: 'equipment/resumeList',
    method: 'GET',
    params: dataObj
  })
}

//获取设备履历说明文件
export function getResumeFileList(dataObj) {
  return instance({
    url: 'equipment/resumeFileList',
    method: 'GET',
    params: dataObj
  })
}