<template>
	<div class="device-resume">
		<div class="title">
			<span class="icon">
				<i :class="menuIcon"></i>
			</span>
			<span class="txt">{{menuName}}</span>
		</div>
		<el-tabs class="tabs" v-model="activeName" stretch>
			<el-tab-pane class="tab-item" label="设备档案" name="first">
				<div class="content1">
					<el-form label-width="150px">
						<el-row :gutter="40">
							<el-col :span="11">
								<el-form-item label="设备名称" class="form-item-box">
									<el-input v-model="resumeList.equipmentName" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="11">
								<el-form-item label="购买日期" class="form-item-box">
									<el-input v-model="resumeList.purchaseDate" readonly></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="40">
							<el-col :span="11">
								<el-form-item label="生产厂家" class="form-item-box">
									<el-input v-model="resumeList.manufacturers" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="11">
								<el-form-item label="启用日期" class="form-item-box">
									<el-input v-model="resumeList.enabledDate" readonly></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="40">
							<el-col :span="11">
								<el-form-item label="价值" class="form-item-box">
									<el-input v-model="resumeList.cost" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="11">
								<el-form-item label="保养方案" class="form-item-box">
									<el-input v-model="resumeList.upkeepName" readonly></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="40">
							<el-col :span="11">
								<el-form-item label="责任人" class="form-item-box">
									<el-input v-model="resumeList.dutyPerson" readonly></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="11">
								<el-form-item label="责任部门" class="form-item-box">
									<el-input v-model="resumeList.dutyDept" readonly></el-input>
								</el-form-item>
							</el-col>
						</el-row> 
						
					</el-form>
				</div>
			</el-tab-pane>
			<el-tab-pane class="tab-item" label="电器规格说明书" name="second">
				<div class="content2">
					<div 
						class="file-item"
						v-for="(item, index) in resumeFileList"
						:key="item.fileName"
						:index="index"
						@click="fileItemClick(item.fileUrl)">
						<span><img src="~assets/img/file.png" alt=""></span>
						<span>{{item.fileName}}</span>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import {
		getResumeList,
		getResumeFileList
	} from 'network/deviceResume'
	export default {
		name: "deviceResume",
		data() {
			return {
				activeName: "first",
				resumeList: [],
				resumeFileList: [],
				baseUrl: "http://120.79.245.4/visual",
			}
		},
		created() {
			this.getResumeList1()
			this.getResumeFileList1()
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		methods: {
			getResumeList1() {
				let obj = {
					stationId: sessionStorage.getItem("stationId")
				}
				getResumeList(obj).then(res => {
					if(res.data.code !== 0)
					return this.$message.error("请求数据失败")
					this.resumeList = res.data.data[0]
				})
			},
			getResumeFileList1() {
				let obj = {
					stationId: sessionStorage.getItem("stationId")
				}
				getResumeFileList(obj).then(res => {
					if(res.data.code !== 0)
					return this.$message.error("请求数据失败")
					this.resumeFileList = res.data.data
				})
			},
			//查看文件
			openFile(filename) {
				let goUrl = this.isMobile();
				if (goUrl === 1) {
					//移动端
					window.external.Openfile(filename);
				} else {
					//PC
					window.open(filename);
				}
			},
			//判断Windows 移动端
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},
			//查看按钮点击事件
			fileItemClick(url) {
				var filename = this.baseUrl + url
				this.openFile(filename)
			},
		}
	}
</script>
<style>
	/*表格字体*/
	.el-table {
		font-size: 1.375rem !important;
	}
	/*表头样式*/
	.el-table th {
		background: rgba(245,247,247,1) !important;
		font-size: 1.375rem !important;
		font-family: PingFang SC !important;
		font-weight: 500 !important;
		color: rgba(58,132,171,1) !important;
	}
	.el-table td, .el-table th {
		height: 3.75rem !important;
		padding: 0 !important;
	}
	.el-table .cell {
		padding: 0.625rem 0 !important;
	}
	.form-item-box > label {
		font-size: 1.25rem !important;
	}
	.el-tabs__item {
		font-size: 1.375rem;
		letter-spacing: 2px;
		height: 3rem;
	}
	.el-tabs__item.is-active {
		color: rgba(58,132,171,1);
	}
	.el-tabs__item:hover {
		color: rgba(58,132,171,1);
	}
	.el-tabs__active-bar {
		background: rgba(58,132,171,1);
	}
</style>
<style lang="scss" scoped>
.device-resume {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	border-radius: 0.625rem;
	box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
	.title {
		width: 100%;
		display: flex;
		padding: 0.625rem;
		.icon {
			display: inline-block;
			width: 2.5rem;
			height: 2.5rem;
			text-align: center;
			line-height: 2.5rem;
			border-radius: 0.625rem;
			margin-right: 1.25rem;
			background:linear-gradient(0deg, rgba(41,187,192,1) 0%, rgba(73,208,206,1) 100%);
			i {
				font-size: 1.375rem;
				font-weight: 500;
				color: white;
			}
		}
		.txt {
			height: 2.5rem;
			font-size: 1.625rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: rgba(58,132,171,1);
			line-height: 2.5rem;
			letter-spacing: 3px;
		}
	}
	.tab-item {
		width: 100%;
		height: 100%;
		.content1 {
			margin-top: 1.875rem;
		}
		.content2 {
			height: 100%;
			display: grid;
			grid-template-columns: auto auto auto auto auto auto auto auto;
			grid-template-rows: auto auto auto auto;
			.file-item {
				margin: 1.25rem 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				span:nth-of-type(1) {
					transition: all 0.3 linear; 
					&:hover {
						transform: scale(1.2, 1.2);
					}
					&:focus {
						transform: scale(1.2, 1.2);
					}
				}
				span {
					font-size: 1.25rem;
					margin-top: 0.625rem;
				}
			}
		}
	}
}
</style>
